<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-3"
        id="kt_wizard_v3"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav border-bottom mb-1 mb-lg-5">
          <div class="wizard-steps px-8 py-8 px-lg-15 py-lg-3">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
              @click="goToStep(1)"
            >
              <div class="wizard-label">
                <h3 class="wizard-title"><span>1</span> Contact Information</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>

            <div
              class="wizard-step"
              data-wizard-type="step"
              @click="goToStep(2)"
            >
              <div class="wizard-label">
                <h3 class="wizard-title">
                  <span>2</span> Statement of Service
                </h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div
              class="wizard-step"
              data-wizard-type="step"
              @click="goToStep(3)"
            >
              <div class="wizard-label">
                <h3 class="wizard-title"><span>3</span> Authentication</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
            <div
              class="wizard-step"
              data-wizard-type="step"
              @click="goToStep(4)"
            >
              <div class="wizard-label">
                <h3 class="wizard-title">
                  <span>4</span> VA and Employee Service Data
                </h3>
                <div class="wizard-bar"></div>
              </div>
            </div>

            <div
              class="wizard-step"
              data-wizard-type="step"
              @click="goToStep(5)"
            >
              <div class="wizard-label">
                <h3 class="wizard-title"><span>5</span> Document</h3>
                <div class="wizard-bar"></div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="row justify-content-center py-12 px-8 py-lg-15 px-lg-10">
          <div class="col-xl-12 col-xxl-10">
            <!--begin: Wizard Form-->
            <form class="form" id="kt_form">
              <!--begin: Wizard Step 1-->
              <div
                class="pb-5"
                data-wizard-type="step-content"
                data-wizard-state="current"
              >
                <h4 class="mb-10 font-weight-bold text-dark">
                  Contact Information
                </h4>

                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Contact Owner :</label>
                      <select
                        name="Contact Owner"
                        v-model="veteran_data.secure_contactOwner"
                        class="form-control form-control-solid form-control-lg"
                      >
                        <option
                          value="Office of Veterans Affairs Virgin Islands"
                        >
                          Office of Veterans Affairs Virgin Islands
                        </option>
                      </select>
                      <span class="form-text text-muted"
                        >Please enter name of contact person.</span
                      >
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group required">
                      <label>Branch of Service :</label>
                      <select
                        name="Contact Owner"
                        v-model="veteran_data.branchOfService"
                        class="form-control form-control-solid form-control-lg"
                      >
                        <option value="" selected disabled hidden>
                          Choose here
                        </option>
                        <option value="Air Force">Air Force</option>
                        <option value="Army">Army</option>
                        <option value="Coast Guard">Coast Guard</option>
                        <option value="Marines">Marines</option>
                        <option value="National Guard">National Guard</option>
                        <option value="Navy">Navy</option>
                        <option value="Space Force">Space Force</option>
                        <option value="Unknown">Unknown</option>
                      </select>
                      <span class="form-text text-muted"
                        >Please enter your branch of service.</span
                      >
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group required">
                      <label>First Name :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="firstName"
                        v-model="veteran_data.firstName"
                      />
                      <span class="form-text text-muted"
                        >Please enter your First Name.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group required">
                      <label>Last Name :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="Last Name"
                        placeholder=" "
                        v-model="veteran_data.lastName"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Last Name.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>U. S. Citizen :</label>
                      <select
                        name="Contact Owner"
                        v-model="veteran_data.secure_usCitizen"
                        class="form-control form-control-solid form-control-lg"
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <span class="form-text text-muted"
                        >Please enter your citizenship.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Registered with the VA Clinic :</label>
                      <select
                        name="Contact Owner"
                        v-model="veteran_data.secure_registeredWithVAClinic"
                        class="form-control form-control-solid form-control-lg"
                      >
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                      <span class="form-text text-muted"
                        >Please enter your Registered with the VA Clinic.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Service No. :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_serviceNo"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Service No..</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Date of Birth :</label>
                      <div class="w-100">
                        <date-picker
                          v-model="veteran_data.secure_dateOfBirth"
                          valueType="format"
                          format="MM/DD/YYYY"
                          class="w-100 border-none form-control form-control-solid form-control-lg datePicker pt-1 pb-1"
                        ></date-picker>
                      </div>
                      <span class="form-text text-muted"
                        >Please enter your Date of Birth.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Selective Service Number :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_selectiveServiceNumber"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Selective Service Number.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Place of Birth :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_placeOfBirth"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Place of Birth.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Social Security Number :</label>
                      <div>
                        <input
                          :type="showSocialSecurityNumber ? 'text' : 'password'"
                          class="form-control form-control-solid form-control-lg"
                          v-model="veteran_data.secure_socialSecurityNumber"
                        />
                        <span
                          @click="
                            showSocialSecurityNumber = !showSocialSecurityNumber
                          "
                          class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 eye-icon"
                          data-kt-password-meter-control="visibility"
                        >
                          {{ showSocialSecurityNumber ? "Hide" : "Show" }}
                          <i class="bi bi-eye-slash fs-2"></i>
                          <i class="bi bi-eye fs-2"></i>
                        </span>
                      </div>
                      <span class="form-text text-muted"
                        >Please enter your Social Security Number.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Pay Grade :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_payGrade"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Pay Grade.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Grade Rate or Rank at Time of Entry :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_gradeRank"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Grade Rate or Rank at Time of
                        Entry.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Date Inducted :</label>
                      <div class="w-100">
                        <date-picker
                          v-model="veteran_data.secure_dateInducted"
                          valueType="format"
                          format="DD/MM/YYYY"
                          class="w-100 border-none form-control form-control-solid form-control-lg datePicker pt-1 pb-1"
                        ></date-picker>
                      </div>
                      <span class="form-text text-muted"
                        >Please enter your Date Inducted.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Station of Installation at Which Effected :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_stationOfInstallation"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Station of Installation at Which
                        Effected.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Last Duty Assignment and Command :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_lastDutyAssignment"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Last Duty Assignment and
                        Command.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Reason For Authority :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_reasonForAuthority"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Reason For Authority.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Type of Transfer or Discharge :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_typeOfTransfer"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Type of Transfer or Discharge.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Prior Regular Enlistment :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_priorRegularEnlistment"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Prior Regular Enlistment.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label
                        >Place of Entry into Current Active Srvc
                        City/State</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_placeOfEntry"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Place of Entry into Current Active
                        Srvc City/State.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Home of Record at Entry :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_homeOfRecord"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Home of Record at Entry.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Type of Certificate Issued :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_typeOfCertificateIssued"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Type of Certificate Issued.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Email :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        name="Email"
                        v-model="veteran_data.secure_userEmail"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Email.</span
                      >
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Type of Certificate :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_typeOfCertificate"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Type of Certificate.</span
                      >
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Character of Service :</label>
                      <select
                        name="Contact Owner"
                        v-model="veteran_data.secure_characterOfService"
                        class="form-control form-control-solid form-control-lg"
                      >
                        <option value="" selected disabled hidden>
                          -None-
                        </option>
                        <option value="Honorable">Honorable</option>
                        <option value="Not Honorable">Not Honorable</option>
                      </select>
                      <span class="form-text text-muted"
                        >Please enter your Character of Service.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label
                        >Terminal Date of Reserve Units Obligations :</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="
                          veteran_data.secure_terminalDateOfReserveUnitsObligations
                        "
                      />
                      <span class="form-text text-muted"
                        >Please enter your Terminal Date of Reserve Units
                        Obligations.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Specialty Number and Title :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_specialityNumber"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Specialty Number and Title.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Reenlistment Code :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_reenlistmentCode"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Reenlistment Code.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label
                        >District Area Command or corp to which reservist</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_districtAreaCommand"
                      />
                      <span class="form-text text-muted"
                        >Please enter your District Area Command or corp to
                        which reservist.</span
                      >
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <label
                        >Related Civilian Occupation and D.O.T number</label
                      >
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_dotNumber"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Related Civilian Occupation and D.O.T
                        number.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Phone :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_phone"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Phone.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Home Phone :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_homePhone"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Home Phone.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Other Phone :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_otherPhone"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Other Phone.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Email Opt Out :</label>
                      <input
                        style="width: 8%; height: 22px"
                        type="checkbox"
                        class="form-control form-control-solid form-control-xs"
                        v-model="veteran_data.secure_emailOpt"
                      />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Mobile Phone :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.mobileNumber"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Mobile Phone.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label
                        >Current Active Service Other Than By Induction</label
                      >
                      <select
                        name="Contact Owner"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_currentActiveService"
                      >
                        <option value="" selected disabled hidden>
                          -None-
                        </option>
                        <option value="Enlisted (First Enlistment)">
                          Enlisted (First Enlistment)
                        </option>
                        <option value="Enlisted (Prior Service)">
                          Enlisted (Prior Service)
                        </option>
                        <option value="Reenisted">Reenisted</option>
                        <option value="Other">Other</option>
                      </select>
                      <span class="form-text text-muted"
                        >Please enter your Current Active Service Other Than By
                        Induction.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6 d-none">
                    <div class="form-group">
                      <label>Gender :</label>
                      <div class="row">
                        <div class="col-6">
                          <div
                            class="d-flex align-items-center justify-content-start"
                          >
                            <input
                              type="radio"
                              class="form-control form-control-solid form-control-xs radioControl"
                              name="gender"
                              value="MALE"
                              v-model="veteran_data.secure_gender"
                            />
                            <span> Male </span>
                          </div>
                        </div>
                        <div class="col-6">
                          <div
                            class="d-flex align-items-center justify-content-start"
                          >
                            <input
                              type="radio"
                              class="form-control form-control-solid form-control-xs radioControl"
                              name="gender"
                              value="FEMALE"
                              v-model="veteran_data.secure_gender"
                            />
                            <span>Female</span>
                          </div>
                        </div>
                      </div>
                      <span class="form-text text-muted"
                        >Please select your gender.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Skype ID :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_skypeId"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Skype ID.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Twitter :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_twitter"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Twitter.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Decorations, Medals, Badges, etc :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_badges"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Decorations, Medals, Badges,
                        etc.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Reporting To :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_reportingTo"
                      />
                      <span class="form-text text-muted"
                        >Please enter your Reporting To.</span
                      >
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group required">
                      <label>Location :</label>
                      <select
                        name="Contact Owner"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.location"
                      >
                        <option value="" selected disabled hidden>
                          Choose here
                        </option>
                        <option value="St. Thomas">St. Thomas</option>
                        <option value="St Croix">St Croix</option>
                        <option value="St. John">St. John</option>
                      </select>
                      <span class="form-text text-muted"
                        >Please enter your Current Active Service Other Than By
                        Induction.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 1-->

              <!--begin: Wizard Step 2-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">
                  Statement of Service:
                </h4>
                <div class="row">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-1">
                          <input
                            style="width: 100%; height: 22px"
                            type="checkbox"
                            class="form-control form-control-solid form-control-xs"
                            v-model="
                              veteran_data.secure_creditableForBasicPayPurposes
                            "
                          />
                        </div>
                        <div class="col-10 pl-0">
                          <label>Creditable For Basic Pay Purposes :</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">&nbsp;</div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Net Service Period (Years/Months/ Days) :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-xs"
                        v-model="veteran_data.secure_netServicePeriod"
                      />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Other Service (Years/ Months/ Days) :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-xs"
                        v-model="veteran_data.secure_otherService"
                      />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Total (Line (1) plus Line (2)) :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-xs"
                        v-model="veteran_data.secure_totalService"
                      />
                    </div>
                  </div>
                  <div class="col-6">&nbsp;</div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Total Active Service :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_totalActiveService"
                      />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Foreign and/or Sea Service :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_foreignService"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 2-->

              <!--begin: Wizard Step 3-->
              <div class="pb-5" data-wizard-type="step-content">
                <h4 class="mb-10 font-weight-bold text-dark">Authentication</h4>
                <div class="row border-bottom pb-5 mb-10">
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Mailing Street :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_street"
                      />
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Mailing City :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_city"
                      />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Mailing State :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_state"
                      />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Mailing Zip :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_zip"
                      />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Mailing Country :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_country"
                      />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <label>Same as above :</label>
                      <input
                        style="width: 2%; height: 22px"
                        type="checkbox"
                        class="form-control form-control-solid form-control-xs"
                        :disabled="disableCopyAdressOption"
                        @change="copyOtherMailingAddress"
                      />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Other Street :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_otherStreet"
                      />
                    </div>
                  </div>

                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Other City :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_otherCity"
                      />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Other State :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_otherState"
                      />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Other Zip :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_otherZip"
                      />
                    </div>
                  </div>
                  <div class="col-xl-6">
                    <div class="form-group">
                      <label>Other Country :</label>
                      <input
                        type="text"
                        class="form-control form-control-solid form-control-lg"
                        v-model="veteran_data.secure_otherCountry"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 3-->

              <!--begin: Wizard Step 4-->
              <div class="pb-5" data-wizard-type="step-content">
                <div class="my-5">
                  <h4 class="mb-10 font-weight-bold text-dark">
                    VA and Employee Service Data
                  </h4>
                  <div class="my-5">
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label
                            >Non-Pay Periods/Time Lost (Proceeding 2
                            Years)</label
                          >
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            v-model="veteran_data.secure_nonPayPeriods"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Education and Training
                            Details.</span
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>Date Accrued Leave Paid :</label>
                          <div class="w-100">
                            <date-picker
                              v-model="veteran_data.secure_dateAccruedLeavePaid"
                              valueType="format"
                              format="MM/DD/YYYY"
                              class="w-100 border-none form-control form-control-solid form-control-lg datePicker pt-1 pb-1"
                            ></date-picker>
                          </div>
                          <span class="form-text text-muted"
                            >Please enter your Education and Training
                            Details.</span
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label
                            >Insurance in Force (NLSI or USGLI)Date Accrued
                            Leave Paid</label
                          >
                          <select
                            name="Contact Owner"
                            v-model="veteran_data.secure_insuranceInForce"
                            class="form-control form-control-solid form-control-lg"
                          >
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          <span class="form-text text-muted"
                            >Please enter your Education and Training
                            Details.</span
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>Amount of Allotment :</label>
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            v-model="veteran_data.secure_amountOfAllotment"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Education and Training
                            Details.</span
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>Month Allotment Discontinued :</label>
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            v-model="
                              veteran_data.secure_monthAllotmentDiscontinued
                            "
                          />
                          <span class="form-text text-muted"
                            >Please enter your Education and Training
                            Details.</span
                          >
                        </div>
                      </div>
                      <div class="col-6">&nbsp;</div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>VA Claim Number</label>
                          <input
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            v-model="veteran_data.secure_VAClaimNumber"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Education and Training
                            Details.</span
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label
                            >Servicemen's Group Life Insurance Coverage</label
                          >
                          <select
                            name="Contact Owner"
                            v-model="
                              veteran_data.secure_servicemensGroupLifeInsuranceCoverage
                            "
                            class="form-control form-control-solid form-control-lg"
                          >
                            <option value="$10000">$10000</option>
                            <option value="$9000">$9000</option>
                            <option value="">-None-</option>
                          </select>
                          <span class="form-text text-muted"
                            >Please enter your Education and Training
                            Details.</span
                          >
                        </div>
                      </div>
                    </div>

                    <h4 class="mt-10 font-weight-bold text-dark">
                      Education and Training Completed
                    </h4>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label>Description</label>
                          <textarea
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            v-model="veteran_data.secure_description"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Education and Training
                            Details.</span
                          >
                        </div>
                      </div>
                    </div>

                    <h4 class="mb-10 font-weight-bold text-dark">Remarks</h4>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label>Remarks</label>
                          <textarea
                            type="text"
                            class="form-control form-control-solid form-control-lg"
                            v-model="veteran_data.secure_remarks"
                          />
                          <span class="form-text text-muted"
                            >Please enter your Education and Training
                            Details.</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--end: Wizard Step 4-->

              <!--begin: Wizard Step 5-->
              <div class="pb-5" data-wizard-type="step-content">
                <div
                  class="d-flex justify-content-between align-items-center mb-5 border-bottom pb-5"
                >
                  <h4 class="font-weight-bold text-dark">Documents</h4>

                  <div
                    class="col-5 pr-0 justify-content-right custom-file-wrapper"
                  >
                    <b-form-file
                      v-model="documentFile"
                      placeholder="Choose a Document to upload..."
                      drop-placeholder="Drop file here..."
                      @change="handleUploadFile"
                    ></b-form-file>
                    <span
                      class="spinner spinner-primary"
                      v-if="disableFileActions"
                    ></span>
                  </div>
                </div>
                <div class="row border-bottom pb-5 mb-5">
                  <div class="col-6 font-weight-bold mt-2">Document Name</div>
                  <div class="col-2 font-weight-bold mt-2">Document Type</div>
                  <div class="col-4 font-weight-bold text-right">Action</div>
                </div>
                <div
                  class="row border-bottom pb-5 mb-5"
                  v-for="document in documents"
                  :key="document.fileId"
                >
                  <div class="col-6 line-height-md mt-2">
                    {{ document.fileName }}
                  </div>
                  <div class="col-2 line-height-md mt-2">
                    {{ document.fileType }}
                  </div>
                  <div class="col-4 text-right">
                    <button
                      type="button"
                      class="btn btn-light-primary btn-sm font-weight-bold text-uppercase"
                      @click="viewFile(document)"
                      :disabled="disableFileActions"
                    >
                      Download
                    </button>
                    <button
                      type="button"
                      class="btn btn-light-danger btn-sm font-weight-bold text-uppercase ml-2"
                      @click="deleteFile(document)"
                      :disabled="disableFileActions"
                    >
                      Delete</button
                    >&nbsp;
                    <!-- <span
                      v-if="disableFileActions"
                      class="spinner spinner-primary"
                    ></span> -->
                  </div>
                </div>
                <div
                  class="row border-bottom pb-5 mb-5"
                  v-if="documents.length === 0"
                >
                  <div class="col-12 line-height-md mt-2">No documents.</div>
                </div>
              </div>
              <!--end: Wizard Step 5-->

              <!--begin: Wizard Actions -->
              <div class="d-flex justify-content-between border-top pt-10">
                <div class="mr-2">
                  <button
                    v-if="currentStep === 1"
                    @click="redirectDashboard"
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                  >
                    Cancel
                  </button>
                  <button
                    v-if="currentStep > 1"
                    @click="goToPrev"
                    class="btn btn-light-primary font-weight-bold text-uppercase px-9 py-4"
                    :disabled="savingDetails"
                  >
                    Previous
                  </button>
                </div>
                <div>
                  <button
                    class="btn btn-primary font-weight-bold text-uppercase px-9 py-4 saveNextBtn"
                    data-wizard-type="action-next"
                    :disabled="savingDetails"
                  >
                    {{ currentStep === 4 ? "Submit" : "Save & Next" }}
                  </button>
                  <span
                    class="spinner spinner-primary saveNextBtn ml-2"
                    v-if="savingDetails"
                  ></span>
                  <!-- <button
                    v-on:click="submitDetail"
                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                    data-wizard-type="action-submit"
                  >
                    Submit
                  </button> -->
                </div>
              </div>
              <!--end: Wizard Actions -->
            </form>
            <!--end: Wizard Form-->
          </div>
        </div>
        <!--end: Wizard Body-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/sass/pages/wizard/contact.scss";
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import {
  CREATE_VETERAN,
  GET_VETERAN_DETAIL,
  UPDATE_VETERAN,
  UPLOAD_VETERAN_DOCUMENT,
  GET_VETERAN_DOCUMENTS_LIST,
  GET_DOCUMENT_PUBLIC_URL,
  DELETE_VETERAN_DOCUMENT,
} from "@/core/services/store/veterans.module";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "Contact",
  components: {
    DatePicker,
  },
  data() {
    return {
      // Remove this dummy login info
      currentStep: 1,
      userId: "",
      veteran_data: {
        secure_contactOwner: "Office of Veterans Affairs Virgin Islands",
        branchOfService: "",
        firstName: "",
        lastName: "",
        secure_usCitizen: "Yes",
        secure_registeredWithVAClinic: "Yes",
        secure_serviceNo: "",
        secure_dateOfBirth: "",
        secure_selectiveServiceNumber: "",
        secure_placeOfBirth: "",
        secure_socialSecurityNumber: "",
        secure_payGrade: "",
        secure_gradeRank: "",
        secure_dateInducted: "",
        secure_stationOfInstallation: "",
        secure_lastDutyAssignment: "",
        secure_reasonForAuthority: "",
        secure_typeOfTransfer: "",
        secure_priorRegularEnlistment: "",
        secure_placeOfEntry: "",
        secure_homeOfRecord: "",
        secure_typeOfCertificateIssued: "",
        secure_userEmail: "",
        secure_typeOfCertificate: "",
        secure_characterOfService: "",
        secure_terminalDateOfReserveUnitsObligations: "",
        secure_specialityNumber: "",
        secure_reenlistmentCode: "",
        secure_districtAreaCommand: "",
        secure_dotNumber: "",
        secure_phone: "",
        secure_homePhone: "",
        secure_otherPhone: "",
        secure_emailOpt: false,
        mobileNumber: "",
        secure_currentActiveService: "",
        secure_skypeId: "",
        secure_twitter: "",
        secure_badges: "",
        secure_reportingTo: "",
        location: "",
        secure_creditableForBasicPayPurposes: false,
        secure_netServicePeriod: "",
        secure_otherService: "",
        secure_totalService: "",
        secure_totalActiveService: "",
        secure_foreignService: "",
        secure_street: "",
        secure_city: "",
        secure_state: "",
        secure_zip: "",
        secure_country: "",
        secure_otherStreet: "",
        secure_otherCity: "",
        secure_otherState: "",
        secure_otherZip: "",
        secure_otherCountry: "",
        secure_nonPayPeriods: "",
        secure_dateAccruedLeavePaid: "",
        secure_insuranceInForce: "No",
        secure_amountOfAllotment: "",
        secure_monthAllotmentDiscontinued: "",
        secure_VAClaimNumber: "",
        secure_servicemensGroupLifeInsuranceCoverage: "",
        secure_description: "",
        secure_remarks: "",
      },
      documents: [],
      wizard: null,
      documentFile: [],
      savingDetails: false,
      fileUploading: false,
      disableFileActions: false,
      showSocialSecurityNumber: false,
    };
  },
  computed: {
    disableCopyAdressOption() {
      return (
        this.veteran_data.secure_street === "" &&
        this.veteran_data.secure_city === "" &&
        this.veteran_data.secure_state === "" &&
        this.veteran_data.secure_zip === "" &&
        this.veteran_data.secure_country === ""
      );
    },
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contact" }]);

    if (this.$route.params.id) {
      this.userId = this.$route.params.id;
      await this.getVeteranDetail();
      this.getDocumentList();
    }

    // Initialize form wizard
    this.wizard = new KTWizard("kt_wizard_v3", {
      startStep: 1, // initial active step number
      clickableSteps: false, // allow step clicking
    });

    // Validation before going to next page
    this.wizard.on("beforeNext", function (wizardObj) {
      // validate the form and use below function to stop the wizard's step
      wizardObj.stop();
    });

    // Change event
    this.wizard.on("change", (wizardObj) => {
      if (!this.userId) {
        wizardObj.stop();
      }
      this.submitDetail();
    });
  },

  methods: {
    redirectDashboard: function (event) {
      event.preventDefault();
      this.$router.push("/dashboard");
    },
    goToStep: function (step) {
      if (this.userId) {
        this.currentStep = step;
        this.wizard.goTo(step);
      }
    },
    goToPrev: function (e) {
      e.preventDefault();
      this.currentStep = this.currentStep - 1;
      this.wizard.goPrev();
      KTUtil.scrollTop();
    },
    getVeteranDetail: async function () {
      const userId = this.$route.params.id;
      try {
        const response = await this.$store.dispatch(GET_VETERAN_DETAIL, userId);
        if (response) {
          this.veteran_data = response;
          this.userId = userId;
        }
      } catch (err) {
        console.error(err);
      }
    },
    getDocumentList: async function () {
      const userId = this.userId;
      try {
        const response = await this.$store.dispatch(
          GET_VETERAN_DOCUMENTS_LIST,
          userId
        );
        if (response.length) {
          this.documents = response;
        }
      } catch (err) {
        console.error(err);
      }
    },
    copyOtherMailingAddress: function (event) {
      const checked = event.target.checked;
      this.veteran_data.secure_otherStreet = checked
        ? this.veteran_data.secure_street
        : "";
      this.veteran_data.secure_otherCity = checked
        ? this.veteran_data.secure_city
        : "";
      this.veteran_data.secure_otherState = checked
        ? this.veteran_data.secure_state
        : "";
      this.veteran_data.secure_otherZip = checked
        ? this.veteran_data.secure_zip
        : "";
      this.veteran_data.secure_otherCountry = checked
        ? this.veteran_data.secure_country
        : "";
    },
    validateFields: function () {
      const requiredFields = [
        this.veteran_data.secure_firstName === "" ? "First Name" : "",
        this.veteran_data.secure_lastName === "" ? "Last Name" : "",
        this.veteran_data.secure_branchOfService === ""
          ? "Branch of Service"
          : "",
        this.veteran_data.secure_location === "" ? "Location" : "",
      ].filter((f) => f !== "");
      if (requiredFields.length > 0) {
        const errMessage = `Please fill ${requiredFields.join(", ")}`;
        Swal.fire({
          title: "",
          text: errMessage,
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
        return false;
      }
      return true;
    },
    submitDetail: async function () {
      // e.preventDefault();
      this.wizard.stop();
      if (this.currentStep === 1) {
        if (!this.validateFields()) {
          return;
        }
      }
      const formData = this.veteran_data;
      this.savingDetails = true;
      try {
        const action = this.userId ? UPDATE_VETERAN : CREATE_VETERAN;

        if (this.userId) {
          formData.userId = this.userId;
        }
        const response = await this.$store.dispatch(action, formData);
        if (response) {
          Swal.fire({
            title: "",
            text: "Details has been successfully saved!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
          if (response.userId) {
            this.userId = response.userId;
          }
          KTUtil.scrollTop();

          this.wizard.goNext();
          this.currentStep = this.currentStep + 1;
          // this.$router.push({ name: "SearchResult" });
        }
      } catch (err) {
        console.error(err);
      }
      this.savingDetails = false;
    },
    handleUploadFile: async function (event) {
      const files = event.target.files;

      if (files.length === 0) {
        return;
      }

      const fileSize = files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 5) {
        Swal.fire({
          title: "",
          text: "Max file upload size is 5MB",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", files[0]);

      this.disableFileActions = true;
      try {
        const response = await this.$store.dispatch(UPLOAD_VETERAN_DOCUMENT, {
          formData,
          userId: this.userId,
        });
        if (response) {
          this.documentFile = null;
          this.getDocumentList();
          Swal.fire({
            title: "",
            text: "Document successfully uploaded!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary",
          });
        }
      } catch (err) {
        if (err?.data?.message) {
          Swal.fire({
            title: "",
            text: err.data.message,
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
          });
        }
      }
      this.disableFileActions = false;
    },
    viewFile: async function (document) {
      this.disableFileActions = true;
      try {
        const response = await this.$store.dispatch(GET_DOCUMENT_PUBLIC_URL, {
          fileId: document.fileId,
          userId: this.userId,
        });
        if (response?.fileUrl) {
          window.open(response?.fileUrl, "_blank");
        }
      } catch (err) {
        console.error(err);
      }
      this.disableFileActions = false;
    },
    deleteFile: async function (document) {
      Swal.fire({
        title: "Are you sure you want to delete this document from system.",
        showCancelButton: true,
        confirmButtonText: "Confirm",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.disableFileActions = true;
          try {
            const response = await this.$store.dispatch(
              DELETE_VETERAN_DOCUMENT,
              { fileId: document.fileId, userId: this.userId }
            );
            if (response) {
              Swal.fire({
                title: "",
                text: "File has been successfully deleted!",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
              });
              this.documents = this.documents.filter(
                (f) => f.fileId !== document.fileId
              );
            }
          } catch (err) {
            console.error(err);
          }
          this.disableFileActions = false;
        }
      });
    },
  },
};
</script>
<style>
.radioControl {
  width: 8% !important;
  margin-right: 10px !important;
}
.datePicker input {
  background-color: #f3f6f9 !important;
  border: #f3f6f9 !important;
  padding: 0;
  box-shadow: none;
}
/*.spinner-primary {
  z-index: 9999;
  position: relative;
  top: -20px;
  left: 15px;
}*/
/*.spinner-primary.saveNextBtn {
  top: 0px !important;
  left: 40% !important;
}*/
.spinner-primary.saveNextBtn::before {
  border-color: red !important;
}
.required {
  position: relative;
}
.required input,
.required select {
  border-left: solid 2px #eb4238 !important;
}
.custom-file-wrapper {
  position: relative;
}
.custom-file-wrapper .spinner-primary {
  position: absolute;
  left: 20px;
  top: 18px;
  z-index: 999;
}
.eye-icon {
  position: absolute;
  right: 25px;
  top: 31px;
}
</style>
